import React from "react";
import "./december.css";

const December: React.FC = () => {
  return (
    <div className="relative h-64 scale-50">
      <div className="globeCanvas">
        <div className="globeShadow"></div>
        <div className="globeBase"></div>
        <div className="bowl">
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snowflake"></div>
          <div className="snow"></div>
          <div className="ground"></div>
          <div className="something"></div>
        </div>
        <div className="bowl">
          <div className="reflection"></div>
        </div>
        <div className="globeBase"></div>
      </div>
    </div>
  );
};

export default December;
