import React from "react";
import "./november.css";
import { useTranslation } from "react-i18next";

const November: React.FC = () => {
  const { t } = useTranslation("common");

  return (
    <div className="relative h-64 scale-50">
      <div className="JT">
        <div className="JT-leg JT-leg_left">
          <div className="JT-leg-shin"></div>
          <div className="JT-leg-foot JT-leg-foot-left"></div>
        </div>
        <div className="JT-leg JT-leg_right">
          <div className="JT-leg-shin"></div>
          <div className="JT-leg-foot JT-leg-foot-right"></div>
        </div>
        <div className="JT-bounce">
          <div className="JT-rock">
            <div className="JT-shake">
              <div className="JT-feathers">
                <div className="JT-feather JT-feather-0"></div>
                <div className="JT-feather JT-feather-1"></div>
                <div className="JT-feather JT-feather-2"></div>
                <div className="JT-feather JT-feather-3"></div>
                <div className="JT-feather JT-feather-4"></div>
                <div className="JT-feather JT-feather-9"></div>
                <div className="JT-feather JT-feather-8"></div>
                <div className="JT-feather JT-feather-7"></div>
                <div className="JT-feather JT-feather-6"></div>
                <div className="JT-feather JT-feather-5"></div>
              </div>
              <div className="JT-bod"></div>
              <div className="JT-sign">Happy Thanksgiving</div>
            </div>
            <div className="JT-head"></div>
            <div className="JT-eye JT-eye-right">
              <div className="JT-eye-iris"></div>
            </div>
            <div className="JT-eye JT-eye-left">
              <div className="JT-eye-iris"></div>
            </div>
            <div className="JT-beak"></div>
            <div className="JT-hat">
              <div className="JT-hat-brim"></div>
              <div className="JT-hat-pipe"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default November;
