"use client";

import { MainAlertProps } from "@/types/common/alerts";
import clsx from "clsx";
import { Banner, Toast } from "flowbite-react";
import posthog from "posthog-js";
import { createContext, useContext, useEffect, useState } from "react";
import { BannerItem, PostHogBannerItem } from "@/types/common/banner";
import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import { AgencyDataUpdate, AgencyStatusResponse } from "@/types/agencies";
import { Modal } from "flowbite-react";
import { SubscriptionStatus } from "@/enums/stripe";
import FancyLoader from "@/components/common/fancyLoader";
import { api, apiLive } from "@/utils/common/api";
import React from "react";
import { HandleOpenBillingSession } from "@/utils/agencies/settings";
import Button from "@/components/library/Button";
import Icon, { ICON_SIZES } from "@/components/library/Icon";
import November from "@/components/festive/november";
import December from "@/components/festive/december";

type PricingTableProps = {
  "pricing-table-id": string;
  "publishable-key": string;
  "customer-session-client-secret": string;
};

const StripePricingTable = (props: PricingTableProps) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": props["pricing-table-id"],
    "publishable-key": props["publishable-key"],
    "customer-session-client-secret": props["customer-session-client-secret"],
  });
};

type DiscountType = {
  code: string;
  discount: string;
};

type GenericModalProps = {
  open: boolean;
  message: string;
  description?: string;
};

type AlertProps = {
  setAlert: (settings: MainAlertProps) => void;
  dismissAlert: () => void;
  showToast: boolean;
  toastSettings: MainAlertProps;
  bannerItems: BannerItem[];
  setBannerItems: (items: BannerItem[]) => void;
  isLoading: GenericModalProps;
  isSaving: GenericModalProps;
  isConfirming: GenericModalProps & {
    mode: "delete" | "confirm" | "choice";
    choices?: string[];
    saveCallback: () => void;
    proceedCallback: () => void;
  };
  setIsLoading: (
    isSaving: boolean,
    message: string,
    description?: string
  ) => void;
  setIsSaving: (
    isSaving: boolean,
    message: string,
    description?: string
  ) => void;
  setIsConfirming: (
    isConfirming: boolean,
    mode: "delete" | "confirm" | "choice",
    message: string,
    saveCallback: () => Promise<void>,
    proceedCallback: () => Promise<void>,
    choices?: string[]
  ) => void;
};

const getIcon = (
  status: "success" | "error" | "warning" | "info"
):
  | "check-circle"
  | "circle-exclamation"
  | "triangle-exclamation"
  | "circle-info" => {
  switch (status) {
    case "success":
      return "check-circle";
    case "error":
      return "circle-exclamation";
    case "warning":
      return "triangle-exclamation";
    case "info":
      return "circle-info";
  }
};

let hasShownSubscriptionModal = false;

export const AlertContext = createContext<AlertProps>({
  setAlert: () => {},
  dismissAlert: () => {},
  showToast: false,
  toastSettings: {
    status: "success",
    message: "",
  },
  bannerItems: [],
  setBannerItems: () => {},
  isLoading: {
    open: false,
    message: "",
  },
  isSaving: {
    open: false,
    message: "",
    description: undefined,
  },
  isConfirming: {
    open: false,
    message: "",
    mode: "confirm",
    choices: undefined,
    saveCallback: () => {},
    proceedCallback: () => {},
  },
  setIsLoading: () => {},
  setIsSaving: () => {},
  setIsConfirming: () => {},
});

export const useAlert = () => useContext(AlertContext)!;

const useAlertFunction = (): AlertProps => {
  //Private State
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  //Public State
  const [showToast, setShowToast] = useState(false);
  const [toastSettings, setToastSettings] = useState<MainAlertProps>({
    status: "success",
    message: "",
  });
  const [bannerItems, setBannerItems] = useState<BannerItem[]>([]);

  const [isSaving, setIsSaving] = useState<GenericModalProps>({
    open: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState<GenericModalProps>({
    open: false,
    message: "",
    description: "",
  });
  const [isConfirming, setIsConfirming] = useState<
    GenericModalProps & {
      mode: "delete" | "confirm" | "choice";
      choices?: string[];
      saveCallback: () => void;
      proceedCallback: () => void;
    }
  >({
    open: false,
    message: "",
    mode: "confirm",
    choices: undefined,
    saveCallback: () => {},
    proceedCallback: () => {},
  });

  useEffect(() => {
    if (window.localStorage) {
      posthog.onFeatureFlags(function () {
        if (posthog.isFeatureEnabled("ui-banner")) {
          const bannerItems: BannerItem[] = posthog.getFeatureFlagPayload(
            "ui-banner"
          ) as PostHogBannerItem[];

          const alreadyAckedIds = window.localStorage
            .getItem("GAIL_banner_ids")
            ?.split(",");

          //remove any banners that have already been seen
          const filteredBanners = bannerItems.filter(
            (banner) => !alreadyAckedIds?.includes(banner.id)
          );

          //sort the banners by status having error, warning, info, success in that order
          const sortedBanners = filteredBanners.sort((a, b) => {
            if (a.status === "error" && b.status !== "error") {
              return -1;
            }

            if (
              a.status === "warning" &&
              b.status !== "error" &&
              b.status !== "warning"
            ) {
              return -1;
            }

            if (a.status === "info" && b.status === "success") {
              return -1;
            }

            return 1;
          });

          setBannerItems(sortedBanners);
        }
      });
    }
  }, []);

  //Public Methods
  const setAlert = (settings: MainAlertProps) => {
    setToastSettings(settings);
    setShowToast(true);

    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    const secondsToClose = settings.secondsToClose || 0;

    if (secondsToClose > 0) {
      const timeout = setTimeout(() => {
        setShowToast(false);
      }, secondsToClose * 1000);

      setTimeoutId(timeout);
    }
  };

  const dismissAlert = () => {
    setShowToast(false);
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  return {
    setAlert,
    dismissAlert,
    showToast,
    toastSettings,
    bannerItems,
    setBannerItems,
    isLoading,
    setIsLoading: (isLoading, message, description) => {
      setIsLoading({
        open: isLoading,
        message: message,
        description: description,
      });
    },
    isSaving,
    setIsSaving: (isSaving, message, description) => {
      setIsSaving({
        open: isSaving,
        message: message,
        description: description,
      });
    },
    isConfirming,
    setIsConfirming: (
      isConfirming,
      mode,
      message,
      saveCallback,
      proceedCallback,
      choices
    ) => {
      setIsConfirming({
        open: isConfirming,
        message: message,
        mode: mode,
        choices: choices,
        saveCallback: saveCallback,
        proceedCallback: proceedCallback,
      });
    },
  };
};

export const AlertContextProvider = ({
  children,
  isAdmin,
  disableBanners = false,
}: {
  children: React.ReactNode;
  isAdmin: boolean;
  disableBanners?: boolean;
}) => {
  const alert = useAlertFunction();
  const { subscription, getToken } = useAuthContext();

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState({
    show: false,
    clientSecret: "",
  });
  const [showLoadingModal, setShowLoadingModal] = useState({
    show: false,
    title: "",
    description: "",
  });
  const [items, setItems] = useState<BannerItem[]>([]);
  const [discount, setDiscount] = useState<DiscountType | null>(null);

  const {
    dismissAlert,
    showToast,
    toastSettings,
    bannerItems,
    setBannerItems,
    isLoading,
    isSaving,
    isConfirming,
    setIsConfirming,
  } = alert;

  const icon = getIcon(toastSettings.status);

  const openSubscriptionModal = async (source: "timer" | "click") => {
    if (hasShownSubscriptionModal && source === "timer") {
      return;
    }

    if (source === "timer") {
      hasShownSubscriptionModal = true;
    }

    setShowLoadingModal({
      show: true,
      title: "Starting Subscription",
      description: "Please wait while we start your subscription...",
    });

    //Call the API to start the stripe creation process
    const response = await apiLive<{
      clientSecret: string;
      success: boolean;
    }>(getToken, "/api/billing/create");

    //if successful route to the billing page in a new tab
    //if failed show an error message as alert
    if (response && response.success) {
      setShowSubscriptionModal({
        show: true,
        clientSecret: response.clientSecret,
      });

      posthog.capture(`SubscriptionModalOpen`);
    } else {
      alert.setAlert({
        status: "error",
        message: "Failed to start subscription",
        secondsToClose: 10,
      });
    }

    //set is loading to false
    setShowLoadingModal({
      show: false,
      title: "",
      description: "",
    });
  };

  const getBannerItemFromSubscription = (
    status: AgencyStatusResponse
  ): BannerItem | null => {
    if (!status || status.status === "active") {
      return null;
    }

    if (status.status === SubscriptionStatus.none) {
      return {
        id: "free-trial",
        status: "info",
        description: "Your account is in limited trial mode.",
        icon: "circle-info",
        isPersistent: true,
        linkAction: async () => {
          openSubscriptionModal("click");
        },
        linkText: "Upgrade to unlock all features!",
      };
    } else if (status.status === SubscriptionStatus.trialing) {
      posthog.capture(`Trialing`, {
        trialDaysLeft: status.trialDaysLeft,
      });

      if ((status.trialDaysLeft ?? 0) > 3) {
        return null;
      }

      return {
        id: "trialing",
        status: "warning",
        description:
          status.trialDaysLeft ?? 0 > 0
            ? `Your trial is ending in ${status.trialDaysLeft ?? 0} days`
            : "Your trial is ending tonight!",
        icon: "circle-info",
        isPersistent: true,
        link: "/settings",
        linkText: "View Billing",
      };
    }

    return {
      id: "canceled",
      status: "error",
      description:
        "Your subscription has ended, we would love to have you back!",
      icon: "circle-exclamation",
      isPersistent: true,
      link: "/settings",
      linkText: "Update Billing",
    };
  };

  useEffect(() => {
    if (subscription.status === SubscriptionStatus.none) {
      const timer = setTimeout(() => {
        openSubscriptionModal("timer");
      }, 120000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [subscription]);

  useEffect(() => {
    if (subscription.flags.needs_signing) {
      setShowTermsModal(true);
    }
  }, [subscription]);

  useEffect(() => {
    if (disableBanners) {
      return;
    }

    if (isAdmin) {
      setItems([
        {
          id: "admin",
          status: "error",
          description: "You are on the Admin Dashboard!",
          icon: "user-crown",
          isPersistent: true,
        },
      ]);
      return;
    }

    const subscriptionBannerItem = getBannerItemFromSubscription(subscription);

    if (subscriptionBannerItem && subscriptionBannerItem.status === "error") {
      setShowBillingModal(true);
    }

    setItems([
      ...(subscriptionBannerItem ? [subscriptionBannerItem] : []),
      ...bannerItems,
    ]);
  }, [subscription, bannerItems, isAdmin, disableBanners]);

  useEffect(() => {
    posthog.onFeatureFlags(function () {
      if (posthog.isFeatureEnabled("ui-discount-code")) {
        const discountData: DiscountType = posthog.getFeatureFlagPayload(
          "ui-discount-code"
        ) as DiscountType;

        setDiscount(discountData);
      }
    });
  }, []);

  const GetMonthlyLoadingAnimation = () => {
    const monthNumber = new Date().getMonth() + 1;

    if (monthNumber === 11) {
      return <November />;
    } else if (monthNumber === 12) {
      return <December />;
    }

    return <FancyLoader />;
  };

  return (
    <AlertContext.Provider value={alert}>
      {!disableBanners && items.length > 0 ? (
        <Banner key={items[0].id}>
          <div
            className={clsx(
              "flex",
              "w-full",
              "justify-between",
              "p-4",
              "md:ml-[-1rem]",
              "w-auto",
              "relative",
              "z-30",
              {
                "bg-panel dark:bg-gray-700": items[0].status === "info",
                "bg-orange-50 dark:bg-yellow-900":
                  items[0].status === "warning",
                "bg-red-50 dark:bg-red-800": items[0].status === "error",
                "bg-green-50 dark:bg-green-900": items[0].status === "success",
              }
            )}
          >
            <div className={clsx("mx-auto", "flex", "items-center")}>
              <p
                className={clsx(
                  "flex",
                  "items-center",
                  "text-sm",
                  "font-normal",
                  "text-gray-700",
                  "dark:text-gray-300"
                )}
              >
                <Icon icon={items[0].icon} size={2} className={clsx("mr-3")} />
                <span className="[&_p]:inline">
                  {items[0].description}
                  {(items[0].link || items[0].linkId || items[0].linkAction) &&
                    items[0].linkText && (
                      <a
                        id={items[0].linkId || "bannerLink"}
                        href={items[0].link}
                        onClick={items[0].linkAction || (() => {})}
                        className={clsx(
                          "decoration-600",
                          "inline",
                          "font-medium",
                          "text-button_primary",
                          "underline",
                          "decoration-solid",
                          "underline-offset-2",
                          "ml-2",
                          "cursor-pointer",
                          "hover:no-underline"
                        )}
                        target="_blank"
                      >
                        {items[0].linkText}
                      </a>
                    )}
                </span>
              </p>
            </div>
            {!items[0].isPersistent && (
              <Banner.CollapseButton
                color="orange"
                className={clsx("border-0", "bg-transparent", "text-gray-700")}
                onClick={() => {
                  const ackedIds =
                    window.localStorage.getItem("GAIL_banner_ids");
                  if (ackedIds) {
                    window.localStorage.setItem(
                      "GAIL_banner_ids",
                      `${ackedIds},${items[0].id}`
                    );
                  } else {
                    window.localStorage.setItem("GAIL_banner_ids", items[0].id);
                  }
                  setBannerItems(items.slice(1));
                }}
              >
                <Icon icon="close" />
              </Banner.CollapseButton>
            )}
          </div>
        </Banner>
      ) : null}
      {children}
      <Toast
        className={clsx(
          "fixed",
          "top-24",
          "right-4",
          "bg-gray-100",
          "max-w-sm",
          "border",
          "z-30",
          {
            "!top-[-100px]": !showToast,
            "top-24": bannerItems.length === 0,
            "top-36": bannerItems.length > 0,
            "md:top-4": bannerItems.length === 0,
            "md:top-16": bannerItems.length > 0,
            "border-green-500": toastSettings.status === "success",
            "border-red-500": toastSettings.status === "error",
            "border-yellow-500": toastSettings.status === "warning",
            "border-blue-500": toastSettings.status === "info",
          }
        )}
      >
        <span className={clsx({ hidden: toastSettings.status !== "success" })}>
          <Icon
            icon={icon}
            className={clsx("text-green-500")}
            size={ICON_SIZES.XL}
          />
        </span>
        <span className={clsx({ hidden: toastSettings.status !== "error" })}>
          <Icon
            icon={icon}
            className={clsx("text-red-500")}
            size={ICON_SIZES.XL}
          />
        </span>
        <span className={clsx({ hidden: toastSettings.status !== "warning" })}>
          <Icon
            icon={icon}
            className={clsx("text-yellow-500")}
            size={ICON_SIZES.XL}
          />
        </span>
        <span className={clsx({ hidden: toastSettings.status !== "info" })}>
          <Icon
            icon={icon}
            className={clsx("text-blue-500")}
            size={ICON_SIZES.XL}
          />
        </span>
        <div className="pl-4 pr-2 text-sm font-normal w-full">
          {toastSettings.message}
        </div>
        {toastSettings.btnLabel && toastSettings.btnAction && (
          <Button
            className={clsx("mx-2", "float-right", {
              "text-green-500": toastSettings.status === "success",
              "text-red-500": toastSettings.status === "error",
              "text-yellow-500": toastSettings.status === "warning",
              "text-blue-500": toastSettings.status === "info",
            })}
            type="tertiary"
            onClick={toastSettings.btnAction}
          >
            {toastSettings.btnLabel}
          </Button>
        )}
        <Toast.Toggle
          className={clsx("bg-gray-100")}
          onDismiss={() => dismissAlert()}
        />
      </Toast>

      <Modal
        id="terms-modal"
        title="Please Read and Accept Terms"
        show={showTermsModal}
      >
        <Modal.Header className={clsx("[&_button]:hidden")}>
          <h2>Please Read and Accept the T&Cs</h2>
        </Modal.Header>
        <Modal.Body>
          <p>
            We are so excited to have you onboard! Please read and accept the
            terms and conditions to continue.
          </p>
          <p className={clsx("mt-4")}>
            Please review the{" "}
            <a
              href="https://www.lula.com/terms-and-conditions"
              target="_blank"
              className={clsx("text-primary")}
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
            . By agreeing you also authorize communications from us via SMS and
            email.
          </p>
          <p className={clsx("mt-4")}>
            Please review the{" "}
            <a
              href="https://www.lula.com/privacy-policy"
              target="_blank"
              className={clsx("text-primary")}
            >
              Privacy policy
            </a>{" "}
            for more information.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className={clsx("w-full")}>
            <Button
              type="primary"
              onClick={async () => {
                setShowTermsModal(false);
                setShowLoadingModal({
                  show: true,
                  title: "Activating Your Account",
                  description: "Activating Your Account",
                });

                await api<AgencyDataUpdate>(
                  getToken,
                  "/api/settings/agency/details",
                  "PUT",
                  {
                    needs_signing: false,
                  }
                );

                setShowLoadingModal({
                  show: false,
                  title: "",
                  description: "",
                });
              }}
              className={clsx("float-right", "ml-4")}
            >
              I Agree
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        id="account-not-active-modal"
        title="Account no Longer Active"
        onClose={() => {
          setShowBillingModal(false);
        }}
        show={showBillingModal}
      >
        <Modal.Header>
          <h2>Your Account is no Longer Active</h2>
        </Modal.Header>
        <Modal.Body>
          <p>
            We would love to have you stay onboard! please update your billing
            information to continue use of GAIL.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className={clsx("w-full")}>
            <Button
              type="primary"
              onClick={() => {
                setShowBillingModal(false);
                HandleOpenBillingSession(getToken, alert.setAlert);
              }}
              className={clsx("float-right", "ml-4")}
            >
              <Icon icon="address-card" className={clsx("mr-2")} />
              <span>Update Billing</span>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        id="loading-modal"
        title="Loading"
        show={showLoadingModal.show}
        dismissible={false}
      >
        <Modal.Body>
          <p className={clsx("text-center")}>{showLoadingModal.description}</p>
          <FancyLoader />
        </Modal.Body>
      </Modal>
      <Modal
        id="confirming-modal"
        title="Confirming"
        show={isConfirming.open}
        dismissible={true}
        onClose={() => {
          setIsConfirming(
            false,
            "confirm",
            "",
            async () => {},
            async () => {}
          );
        }}
      >
        <Modal.Header>{isConfirming.message.split("\n")[0]}</Modal.Header>
        {isConfirming.message.split("\n").length > 1 && (
          <Modal.Body>
            {isConfirming.message.split("\n").map((message, index) => {
              if (index === 0) {
                return;
              }

              return <p key={index}>{message}</p>;
            })}
          </Modal.Body>
        )}
        <Modal.Footer>
          <div
            className={clsx("grid", "gap-4", "grid-cols-1", "md:grid-cols-2")}
          >
            <Button
              type="primary"
              wide
              onClick={async () => {
                await isConfirming.saveCallback();
                setIsConfirming(
                  false,
                  "confirm",
                  "",
                  async () => {},
                  async () => {}
                );
              }}
            >
              {isConfirming.mode === "delete"
                ? "Remove"
                : isConfirming.mode === "confirm"
                ? "Save & Continue"
                : isConfirming.choices && isConfirming.choices.length > 0
                ? isConfirming.choices[0]
                : "Yes"}
            </Button>
            <Button
              type="secondary"
              wide
              onClick={async () => {
                await isConfirming.proceedCallback();
                setIsConfirming(
                  false,
                  "confirm",
                  "",
                  async () => {},
                  async () => {}
                );
              }}
            >
              {isConfirming.mode === "delete"
                ? "Cancel"
                : isConfirming.mode === "confirm"
                ? "Continue Anyways"
                : isConfirming.choices && isConfirming.choices.length > 1
                ? isConfirming.choices[1]
                : "No"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        id="subscription-modal"
        title="Choose Your Subscription"
        show={showSubscriptionModal.show}
        onClose={() => {
          setShowSubscriptionModal({
            show: false,
            clientSecret: showSubscriptionModal.clientSecret,
          });
        }}
        size={"4xl"}
      >
        <Modal.Header>
          <h2>Ready to Purchase?</h2>
        </Modal.Header>
        <Modal.Body>
          <div
            className={clsx(
              "mb-6",
              "grid",
              "gap-4",
              "grid-cols-1",
              "md:grid-cols-2"
            )}
          >
            <div className={clsx("max-w-sm", "mx-auto")}>
              <span className={clsx("mb-2", "text-lg", "block")}>You Can:</span>
              <ul
                className={clsx(
                  "list-disc",
                  "mx-auto",
                  "inline-block",
                  "mb-4",
                  "list-inside"
                )}
              >
                <li>Continue trying GAIL</li>
                <li>Book a demo</li>
                <li className={clsx("font-semibold")}>
                  Purchase and Schedule Onboarding
                </li>
              </ul>
            </div>
            <div className={clsx("max-w-sm", "mx-auto")}>
              We are excited you have decided to try GAIL. If you are ready to
              purchase we would love to help with your onboarding.
              {discount && (
                <>
                  <h3
                    className={clsx("mt-4", "mb-2", "text-lg", "font-semibold")}
                  >
                    We have a discount for you!
                  </h3>
                  <p>
                    Use Code{" "}
                    <span className={clsx("text-xl", "font-semibold")}>
                      {discount.code}
                    </span>{" "}
                    for{" "}
                    <span className={clsx("text-xl", "font-semibold")}>
                      {discount.discount}
                    </span>{" "}
                    off your first month
                  </p>
                </>
              )}
            </div>
          </div>
          <div className={clsx("grid", "checkout_container")}>
            {showSubscriptionModal.clientSecret ? (
              <StripePricingTable
                pricing-table-id={
                  process.env.NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID || ""
                }
                publishable-key={
                  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || ""
                }
                customer-session-client-secret={
                  showSubscriptionModal.clientSecret
                }
              />
            ) : (
              <FancyLoader />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="secondary"
            onClick={() =>
              setShowSubscriptionModal({
                show: false,
                clientSecret: showSubscriptionModal.clientSecret,
              })
            }
          >
            Continue Trying GAIL
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isLoading.open}>
        <Modal.Body>
          <h1
            className={clsx("text-center", "text-xl", {
              "mb-4": !!isLoading.description,
            })}
          >
            {isLoading.message}
          </h1>
          {isLoading.description && (
            <p className={clsx("text-center")}>{isLoading.description}</p>
          )}
          {GetMonthlyLoadingAnimation()}
        </Modal.Body>
      </Modal>
      <Modal show={isSaving.open}>
        <Modal.Body>
          <h1 className={clsx("text-center")}>{isSaving.message}</h1>
          {isSaving.description && (
            <p className={clsx("text-center")}>{isSaving.description}</p>
          )}
          {GetMonthlyLoadingAnimation()}
        </Modal.Body>
      </Modal>
    </AlertContext.Provider>
  );
};
